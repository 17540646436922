<template>
  <div>
      <Card class="m-b-10">
        <p slot="title"> {{ advertiser.name }}</p>
        <p slot="extra">
          <Tag type="border" color="red">{{advertiser.typeName}}</Tag>
        </p>
        <Row>
          <i-col :xs="24" :sm="24" :md="12" :lg="12">
            <h3>{{ advertiser.orgCode }}</h3>
            <div class="m-b-5">
              <label class="title m-r-5">所属媒介主</label><span>{{advertiser.publisherName}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">联系电话</label><span>{{advertiser.tel}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">地址</label><span>{{advertiser.address}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">客户备注信息</label><span>{{advertiser.description||'-'}}</span>
            </div>
             <div class="m-b-5">
              <label class="title m-r-5">客户来源</label><span>{{advertiser.adFromName||'-'}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">公司类型</label><span>{{advertiser.companyTypeName||'-'}}</span>
            </div>
          </i-col>

          <i-col :xs="24" :sm="24" :md="12" :lg="12">
            <h3>报备来源</h3>
            <div class="m-b-5">
              <label class="title m-r-5">报备渠道</label><span>{{createCompanyName}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">报备用户</label><span>{{task.userName}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">报备时间</label><span>{{task.createTime}}</span>
            </div>
            <!-- <div class="m-b-5">
              <label class="title m-r-5">当前归属渠道</label><span>{{advertiser.currentCompanyName===null?' 暂无 ':advertiser.currentCompanyName}}</span>
            </div>
            <div class="m-b-5">
              <label class="title m-r-5">当前归属销售</label><span>{{advertiser.currentUserName===null?' 暂无 ':advertiser.currentUserName}}</span>
            </div> -->
          </i-col>
        </Row>
      </Card>
      <Tabs>
        <TabPane label="待审批品牌">
          <Divider dashed><h3>待审批品牌</h3></Divider>
          <Card style="width:380px">
            <p slot="title">{{newBrand.name}}</p>
            <p slot="extra" class="text-red">{{newBrand.statusName}}</p>
            <Row>
              <i-col :xs="24" :sm="24" :md="12" :lg="12" v-if="newBrand.keyword">
                <dt  class="title m-r-5">品牌关键字</dt>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12" v-if="newBrand.keyword">
                <dd>{{newBrand.keyword}}
                </dd>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12" v-if="defualtIndustryType !== 2">
                <dt  class="title m-r-5">租户行业</dt>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12"  v-if="defualtIndustryType !== 2">
                <dd>{{newBrand.industryName}}
                  <!-- <Icon
                    @click.native="EditIndustry"
                    type="md-create"
                    style="margin-left:30px;color:green;cursor:pointer;"
                    title="编辑行业"
                  ></Icon> -->
                </dd>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <dt  class="title m-r-5">平台行业</dt>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <dd>{{newBrand.platformIndustryName}}
                  <!-- <Icon
                    @click.native="EditIndustry"
                    type="md-create"
                    style="margin-left:30px;color:green;cursor:pointer;"
                    title="编辑行业"
                  ></Icon> -->
                </dd>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <dt  class="title m-r-5">创建人</dt>
              </i-col>
              <i-col :xs="24" :sm="24" :md="12" :lg="12">
                <dd>{{newBrand.userName}}</dd>
              </i-col>
            </Row>
          </Card>
        </TabPane>
        <TabPane label="附件列表">
          <customer-files v-if="advertiser.id" :customerId="advertiser.id"></customer-files>
        </TabPane>
      </Tabs>
      <Divider dashed v-if="brands.length>0"><h3>已审批品牌</h3></Divider>
      <Row>
        <i-col v-for="(brand,i) in brands" :key="i" span="6">
          <Card>
            <p slot="title">{{brand.name}}</p>
            <p><strong>{{brand.statusName}}</strong></p>
            <dl>
              <dt>品牌关键字</dt>
              <dd>{{brand.keyword}}</dd>
              <dt>行业</dt>
              <dd>{{brand.industryName}}</dd>
              <dt>创建人</dt>
              <dd>{{brand.userName}}</dd>
            </dl>
          </Card>
        </i-col>
      </Row>

      <!-- <brand-searcher :name="newBrand.keyword" v-if="flag"></brand-searcher> -->
      <div v-if="flag">
        <Divider dashed><h3>检索全部品牌</h3></Divider>
        <Row style="margin:2px 2px 2px 2px">
          <i-col span="8">
            <i-input v-model="brandQuery.keyWords" size="small" ></i-input>
          </i-col>
          <i-col span="4">
            <i-button icon="ios-search" size="small" type="primary" @click="getPage">搜索</i-button>
          </i-col>
        </Row>
        <i-table stripe size="small" :data="list" :columns="titles" height="200"></i-table>
      </div>

    <Modal v-model="EditBrandIndustry" title="编辑行业" :closable="false" :mask-closable="false" @on-ok="UpdateBrandIndustry">
      <Select v-model="industryId" size="small" placeholder="所属行业">
        <Option
          v-for="industry in industrydata"
          :value="industry.id"
          :key="industry.id"
        >{{industry.name}}</Option>
      </Select>
    </Modal>

  </div>
</template>

<script>
import { getAdvertiser } from '@/api/crm/advertiser'
import { getCompany } from '@/api/os/company'
import { getBrandList, updateBrandKeyword, updateIndustry, getbycompanybrandid, hasProtectBrand, getPublisherBrandList } from '@/api/crm/brand'
import { getRootIndustryList } from '@/api/os/industry'

import { formatBrandStatus } from '@/utils/tagStatus'
import CustomerFiles from '@/components/advertiser/CustomerFiles'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    CustomerFiles
  },
  data () {
    return {
      defualtIndustryType: this.$store.getters.token.publisherSetting.industryType, // 后台配置的行业类型
      EditBrandKeyword: false,
      createCompanyName: '', // 报备渠道
      msg: '',
      industrydata: [],
      industryId: '',
      newBrand: {},
      brands: [],
      advertiser: {
        id: null,
        name: '',
        orgCode: '',
        address: '',
        industryId: null,
        type: null,
        tel: '',
        description: '',
        userId: '',
        createTime: '',
        updateTime: ''
      },
      query: {
        advertiserId: 0,
        userId: '',
        brandId: ''
      },
      flag: false,
      EditBrandIndustry: false,
      brandQuery: {
        keyWords: '',
        publisherId: this.$store.getters.token.userInfo.publisherId
      },

      list: [],
      titles: [

        { title: '客户', key: 'advertiserName' },
        { title: '品牌名称', key: 'name' },
        // {
        //   title: '品牌关键字',
        //   key: 'keyword'
        // },
        {
          title: '所属行业',
          key: 'industryName'
        },
        {
          title: '品牌状态',
          render: (h, data) => {
            return formatBrandStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '所属公司/销售',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.userName === ''
                  ? params.row.companyName === null
                    ? '-'
                    : params.row.companyName
                  : params.row.companyName +
                      '(' +
                      params.row.userName +
                      ')'
              )
            ])
          }
        }
      ]
    }
  },
  methods: {
    Editkeyword () {
      this.msg = this.newBrand.keyword
      this.EditBrandKeyword = true
    },
    initIndustry () {
      const parms = {
        type: 1,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      getRootIndustryList(parms).then(res => {
        this.industrydata = res
        this.industryId = res[0].id
      })
    },
    EditIndustry () {
      this.initIndustry()
      this.EditBrandIndustry = true
    },
    UpdateBrandKeyword () {
      const data = {
        brandId: this.id,
        keyword: this.msg
      }
      updateBrandKeyword(data).then(res => {
        getbycompanybrandid({ companyBrandId: this.id }).then(res => {
          this.newBrand = res
          this.validProtectBrand()
        })
      })
    },
    UpdateBrandIndustry () {
      const data = {
        brandId: this.id,
        industryId: this.industryId
      }
      updateIndustry(data).then(res => {
        getbycompanybrandid({ companyBrandId: this.id }).then(res => {
          this.newBrand = res
          this.validProtectBrand()
        })
      })
    },
    init () {
      this.query.userId = this.$store.getters.token.userInfo.userId
      this.query.brandId = this.id
      getbycompanybrandid({ companyBrandId: this.query.brandId }).then(res => {
        this.newBrand = res
        this.query.advertiserId = res.advertiserId
        this.brandQuery.keyWords = this.newBrand.name
        this.getPassedBrands()
        this.getAdvertiserDetail()
        this.validProtectBrand()
      }).then(result => {
        this.getPage()
      })

      // 获取客户品牌报备的所属渠道
      getCompany({ companyId: this.task.companyId }).then(res => {
        this.createCompanyName = res.name
      })
    },
    getPassedBrands () {
      this.query.status = 2
      getBrandList(this.query).then(res => {
        this.brands = res
      })
    },
    getAdvertiserDetail () {
      getAdvertiser(this.query).then(res => {
        this.flag = true
        this.advertiser = res
      })
    },
    validProtectBrand () {
      const data = {
        keyword: this.newBrand.keyword,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }
      hasProtectBrand(data).then(res => {
        this.$emit('on-checked', res)
      })
    },
    // 品牌搜索
    getPage () {
      getPublisherBrandList(this.brandQuery).then(res => {
        this.list = res
      })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>
dd {
  font-weight: bolder;
}

.container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

.msg {
  margin: 5px;
}

.select-panel {
  height: 0;
  z-index: 999;
}

.select-item {
  /*height: 0;*/
  z-index: 999;
  margin: 1px;
  padding: 2px;
  background: #fff;
  opacity: 0.8;
}
</style>
